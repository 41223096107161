import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import "./assets/styles/main.scss";
import defaultAxios from "./axios";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import {
//   faCalendar,
//   faPencil,
//   faUserGroup,
//   faToggleOff,
//   faUser,
//   faCube,
//   faIndustry,
//   faChartBar,
//   faChartLine,
//   faChevronDown,
//   faAngleUp,
//   faWrench,
//   faCodeBranch,
//   faBuilding,
//   faList,
//   faPowerOff,
// } from "@fortawesome/free-solid-svg-icons";

import { fas } from "@fortawesome/free-solid-svg-icons";

Vue.component("fa-icon", FontAwesomeIcon);
library.add(fas);
Vue.component("vue-fontawesome", FontAwesomeIcon);
Vue.component("font-awesome-icon", FontAwesomeIcon);
// axios.defaults.baseURL = window.location.hostname === 'localhost' ? 'https://admin.new.idm-online.kz/' : 'https://admin.new.idm-online.kz/'

Vue.prototype.$axios = defaultAxios;

Vue.config.productionTip = false;

Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
  customIconPacks: {
    fas: {
      sizes: {
        default: "sm",
        "is-small": "1x",
        "is-medium": "2x",
        "is-large": "3x"
      },
      iconPrefix: "",
    },
  },
});


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
