import axios from "axios";
import router from "./router";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL || "https://admin.new.idm-online.kz/api/",
});

const cancelToken = () => axios.CancelToken?.source();

axiosInstance.defaults.headers.common["Content-Type"] = "application/json";
axiosInstance.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

let token = localStorage.getItem('token');
if (token) axiosInstance.defaults.headers.common['token'] = token;

axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response?.status === 401) {
    localStorage.removeItem('token')
    router.push('Login')
  }
  return Promise.reject({ error });
});

export default axiosInstance;
export {
  cancelToken,
};
