<template>
  <div class="has-background-white is-flex is-align-items-center is-justify-content-space-between px-3 nav-bar">
    <div class="nav-bar__image-container">
      <img
        src="@/assets/logo.png"
        alt="logo"
      />
    </div>
    <div class="exit-icon">
      <img
        src="@/assets/avatar.png"
        alt="avatar"
      >
      <div class="dropdown-content has-background-white" @click="Logout">
        <fa-icon
          class="icon-gen has-text-weight-light  has-text-danger is-size-6 "
          :icon="['fas','power-off']"
        />
        <a
          class="has-text-dark ml-1"
        >Выйти
        </a>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  methods: {
    Logout() {
      localStorage.removeItem("token");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.nav-bar__image-container {
  width: 80px;
  height: 80px;
  display: grid;
  place-items: center;
}

.nav-bar {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.exit-icon {
  height: 70px;
  width: 70px;
  position: relative;
}

.dropdown-content {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 60px;
  right: 5px;
  background-color: blue;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
  padding: 10px 15px;
  z-index: 1;
}

.exit-icon:hover .dropdown-content {
  transition: .5s;
  display: block;
}
</style>
