<template>
   <div class="page">
      <router-view/>
    </div>
</template>

<script>
export default {
  
}
</script>

<style>
.page{
   background-color:#dddce1;
   height: 100%;
}
</style>