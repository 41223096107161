<template>
  <div class="page">
    <div class="mb-5">
      <NavBar />
    </div>
    <div class="columns">
      <div class="column">
        <Home />
      </div>
      <div class="column is-10">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Home from "@/views/Home.vue";

export default {
  components: {
    NavBar,
    Home,
  },
};
</script>

<style>
.page {
  background-color: #dddce1;
  position: relative;
}

/* .link-cr{
   color: #248afd;
   font-size: inherit;
} */
</style>
